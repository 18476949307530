<template>
  <v-dialog
    v-model="showFormDialog"
    @click:outside="closeForm"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.name"
                  :label="$t('clients.fields.name')"
                  :rules="rules.name"
                ></v-text-field>

                <v-text-field
                  v-model="form.company"
                  :label="$t('clients.fields.company')"
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray"
            :disabled="sending || savedItem != null"
            @click="closeForm"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            :disabled="sending || savedItem != null"
            color="primary"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
          $t(errorMessage)
      }}</v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0"
        >{{ $t("clients.saved", savedItem) }}</v-alert
      >
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";
import validate from "@/plugins/validate";

export default {
  mixins: [crudMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      apiQuery: "/clients/",
      defaultItem: {
        name: "",
        email: "",
        password: "",
      },
      rules: {
        name: [
          (v) => !!v || this.$t("validation.required", {name: this.$t("clients.fields.name") }),
          () => this.getInputError('name', {
            min: 2,
            name: this.$t("clients.fields.name"),
          })
        ],
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedItem === null
        ? this.$t("clients.add")
        : this.$t("clients.edit");
    },
  },

  methods: {},
};
</script>
