<template>
  <v-container id="user-table" fluid tag="section">
    <base-panel icon="mdi-home-group" :title="$t('clients.page title')">
      <clients-form
        ref="editForm"
        :data="meta"
        @itemSaved="onSaved"
      ></clients-form>

      <delete-dialog
        ref="deleteConfirm"
        :url="apiQuery"
        :title="$t('clients.delete')"
        @itemDeleted="onDeleted"
      >
        <template v-slot:default="item">
          {{ $t("clients.confirm delete", item) }}</template
        >

        <template v-slot:error="error">
          <v-alert v-if="error.message" tile class="mb-0" type="error">{{
            error.message
          }}</v-alert>
        </template>

        <template v-slot:confirmed="item">
          <v-alert v-if="item.id" tile type="success" class="mb-0">{{
            $t("clients.deleted", item)
          }}</v-alert>
        </template>
      </delete-dialog>

      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        multi-sort
        :footer-props="{
          'items-per-page-options': [10, 20, 50],
        }"
      >
        <template v-slot:[`item.id`]="{ item }">
          <v-chip v-if="item.isNew" color="green">{{ item.id }}</v-chip>
          <span v-else>{{ item.id }}</span>
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer />
            <v-btn color="primary" dark class="mb-2" @click="onAdd">
              <v-icon left>mdi-home-plus</v-icon>
              {{ $t("clients.add") }}
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="onEdit(item)">mdi-pencil</v-icon>
          <v-icon small v-if="item.id" @click="onDelete(item)"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </base-panel>
  </v-container>
</template>

<script>
import crudMixin from "mixins/crudTable";
import ClientsForm from "./ClientsForm";
import DeleteDialog from "@/components/crud/DeleteDialog";

export default {
  components: {
    ClientsForm,
    DeleteDialog,
  },
  mixins: [crudMixin],
  data() {
    return {
      apiQuery: "/clients/",
      headers: [
        { text: "ID", align: "start", value: "id" },
        { text: this.$t("clients.fields.name"), value: "name" },
        { text: this.$t("clients.fields.company"), value: "company" },
        { text: this.$t("clients.projects"), value: "total_projects" },
        { text: this.$t("clients.actions"), value: "actions", sortable: false },
      ],
      meta: null,
    };
  },
  created() {
    this.loadMeta();
  },
  methods: {},
};
</script>
